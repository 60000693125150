import Mock from "../mock";

const database = {
  information: {
    name: 'Binod Gurung',
    aboutContentShort: "I am a fullstack developer with 10 years of hands-on experience with different technologies in multi-cultural environment. I am also a certified cloud developer with Microsoft Azure and AWS.",
    aboutContentLong: "I am a skilled Full Stack Software Developer with 10 years of hands-on multi-cultural experience implementing new technologies to develop high-performance, secure, and data intensive applications. Expertly defined client requirements for several projects by communicating with key stakeholders and ensuring comprehensive understanding by team members. I worked mostly with ReactJS in the frontend and .NET technologies in the backend backed up by Microsoft SQL Server. I worked with different microsoft Azure services. In addition to that I have an year of academic experice with Java, Angular, MongoDB and AWS services.\nBeside my work life, I love to do sports, workouts, riding. I love the peaceful places with fresh air. Being born in a mountain of Nepal, I love to do down hills and up hills hiking whenever possible.",
    age: '',
    phone: '',
    nationality: 'Nepalese',
    language: 'English, Nepalese',
    email: '',
    address: 'Jacksonville, FLorida USA',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: 'https://www.facebook.com/meBinodGrg/',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/binodgurung66/',
      dribbble: '',
      github: 'https://github.com/binod318/'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/Binod.Gurung.Resume.pdf'
  },
  services: [
    {
      title: "Cloud Development",
      icon: 'cloud',
      details: "Experienced with highly available and reliable cloud application development with Microsoft Azure and AWS."
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "Highly experienced with fullstakc web development with .NET and ReactJS using MS SQL as a DB server."
    },
    {
      title: "API Development",
      icon: 'code-alt',
      details: "Experienced developing minimal APIs and complex APIs with C# and Express JS in microservice architecture."
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "Experienced with cross platform mobile application development and native android application development."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Binod and I worked together for 9 years, and I have seen his growth closely during those days. He has been one of the best developers in my team. He is a developer on whom I can always rely on because of his dedication and focus to his tasks. He is among the very few developers who is so focused and dedicated. He feels the responsibility, possess good analytical and technical skill set and is willing to learn new technologies. He has worked on multiple technologies and has always proved himself. He is an honest person by nature.",
      author: {
        name: 'Prakash Rai',
        designation: 'Sr. Project Manager, Javra Software'
      }
    },
    {
      id: 2,
      content: "I have worked as a Product Owner together with Binod. I am very functional oriented, meaning that I don't know about the details of software development. The applications, features, changes that I explained to Binod from a fucntional Point of View, were always translated very good by Binod into working applications or features. His competence of translating wishes into working solutions is very good. It has always been a pleasure working with Binod.",
      author: {
        name: 'Adri Bruijnis',
        designation: 'Business Analyst R&D, Enza Zaden (Client)'
      }
    },
    {
      id: 3,
      content: "I have enjoyed working with Binod for about 8 years. He is always positive, constructive and thoughtful and has good ideas. He is very good in problem solving. He masters the technical aspects of software development with Azure Devops and Microsoft Visual Studio. He is always willing to go the extra mile and is eager to learn and apply new techniques. He is a good team player and a pleasant person to work with. I definitly recommend Binod as a senior developer in software projects.",
      author: {
        name: 'Carla Buis',
        designation: 'Product Owner, Enza Zaden (Client)'
      }
    },
    {
      id: 4,
      content: "It was great having Binod in my Web Application Programming class in October of 2022. Not only did he have excellent scores across the board (including a perfect score on his final exam), I also found him to be an excellent communicator. He frequently asked deep and insightful questions on the topics at hand, deepening his own understanding and enlivening the classroom discussion. He also was very punctual and had excellent attendance. Just all in all one of the very best students I've had the pleasure of working with this year.",
      author: {
        name: 'Michael Zijlstra',
        designation: 'Assistant Professor, Maharishi International University'
      }
    }
  ],
  skills: [
    {
      title: "C#",
      value: 95
    },
    {
      title: ".NET",
      value: 90
    },
    {
      title: "CSS",
      value: 80
    },
    {
      title: "Javascript",
      value: 80
    },
    {
      title: "ReactJS",
      value: 80
    },
    {
      title: "MS SQL",
      value: 80
    },
    {
      title: "Progress 4GL",
      value: 70
    },
    {
      title: "TypeScript",
      value: 65
    },
    {
      title: "Angular",
      value: 65
    },
    {
      title: "Photoshop",
      value: 65
    },
    {
      title: "Cloud Computing",
      value: 60
    },
    {
      title: "Java",
      value: 50
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "My Portfolio Website",
      subtitle: "A website to showcase my complete portfolio, developed using ReactJS and AWS Cloud technologies. The whole application itself is loaded from Amazon S3 via CloudFront distribution. This page has blogs section written by myself. This page allows the viewer to contact me via Contact Me section. Once the viewer fills the detail and clicks the Send then behind the scene an API is called that directly hits the API Gateway first. Which then calls the Lambda, which does two works. First is to send the email notification to the subscribers through a topic defined in Amazon SNS then it sends the message to Amazon SQS, which triggers the second Lambda. The second Lambda then writes the data to DynamoDB. Both of these Lambdas have enough permission to write data to other AWS services.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/my-portfolio-diagram.jpg"],
      url: 'https://binodgurung.click'
    },
    {
      id: 2,
      title: "Alumni Tracking System",
      subtitle: "An application for faculty and students to register, to post and view advertisements, faculty can review students, and admin can manage faculty and student accounts, developed using SpringBoot and ReactJS with PostgresSQL database server.",
      imageUrl: "/images/portfolio-image-2.png",
      url: 'https://binodgurung.click'
    },
    {
      id: 3,
      title: "Personal Hobby Project",
      subtitle: "A complete working application built on MEAN stack that can be used to store information about top Rock artists around the globe with songs listed on top 10 billboard list. UI is developed using SPA framework Angular, APIs are written in Express.js and database is the MongoDB.",
      imageUrl: "/images/portfolio-image-3.jpg",
      url: 'https://binodgurung.click'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2023 - Present",
        position: "Fullstack Software Engineer",
        company: "ICE",
        details: "Developed a complete web application for Loss Mitigation using microservice architecture using .NET, ReactJS and Java."
      },
      {
        id: 2,
        year: "2023 - 2023",
        position: "Fullstack Software Engineer",
        company: "Black Knight, Inc",
        details: "Developed a complete web application for Loss Mitigation using microservice architecture using .NET, ReactJS and Java."
      },
      {
        id: 3,
        year: "2021 - 2022",
        position: "Technical Lead",
        company: "Javra Software Nepal",
        details: "Oversaw a creative 5-person team that identified and discussed new technologies to implement in client and in-house projects as part of weekly technical update meetings. Successfully executed DevOps role to achieve development and deployment at the same time."
      },
      {
        id: 4,
        year: "2019 - 2021",
        position: "Associate Technical Lead",
        company: "Javra Software Nepal",
        details: "Drove a 4-person team to develop and support applications and to ensure full understanding by the team. Developed SPA using React that consumed Web API developed in .NET with SQL server"
      },
      {
        id: 5,
        year: "2017 - 2019",
        position: "Senior Software Engineer",
        company: "Javra Software Nepal",
        details: "Modernized a legacy application into modular application using new technology and architecture. Implemented Azure storage service to store images from the mobile application using Xamarin."
      },
      {
        id: 6,
        year: "2016 - 2017",
        position: "Associate Senior Software Engineer",
        company: "Javra Software Nepal",
        details: "Implemented cross-platform mobile application development using Xamarin platform. Collaborated with cross functional teams from different cultural/technical backgrounds. Facilitated authentication and authorization on mobile and web applications using JWT Token."
      },
      {
        id: 7,
        year: "2013 - 2016",
        position: "Software Engineer",
        company: "Javra Software Nepal",
        details: "Contributed to development and maintenance of legacy applications and WinRT application development. Implemented projects through all phases of software development life cycle in a Scrum environment."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2022 - 2025",
        graduation: "Master of Computer Science",
        university: "Maharishi International University",
        details: "Completed in-campus study for Masters of Science in Computer science, finishing the degree vai distant education by 2025."
      },
      {
        id: 2,
        year: "2008 - 2012",
        graduation: "Bachelor of Computer Engineering",
        university: "Pokhara University",
        details: "Completed Bachelor of Science in Computer Engineering with Excelletn grade."
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Serverless Computing: AWS Lambda VS Azure Functions',
      featuredImage: '/images/blog-image-1.png',
      filesource: '../../blog/serverless-computing-aws-lambda-vs-azure-functions.md',
      createDay: "15",
      createMonth: 'May',
      createYear: "2024"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+641-233-9203'],
    emailAddress: ['binodgurung66@outlook.com', 'binod.gurung@miu.edu'],
    address: "Jacksonville, Florida, USA"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});